import React, { useState, useEffect } from "react";
import { Box, Button, Skeleton } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Carne from "../assets/CatCarne.png";
import Oferta from "../assets/CatOfer.png";
import Pollo from "../assets/CatPollo.png";
import Cerdo from "../assets/CatCerdo.png";
import Achuras from "../assets/CatAchuras.jpg";
import Embutidos from "../assets/Embutidos.jpg";
import Milanesas from "../assets/Milanesas.jpeg";
import logo from "../assets/LogoDeTernera.png";

const categoryImages = {
  "Productos De Ternera": Carne,
  "Productos de Pollo": Pollo,
  "Productos de Cerdo": Cerdo,
  "Achuras": Achuras,
  "Embutidos": Embutidos,
  "Milanesas": Milanesas,
};

const Categorias = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const { categories } = useSelector((state) => state);
  const [hasShownSkeleton, setHasShownSkeleton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const formatUrl = (name) => {
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-');
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
      setIsTablet(width > 768 && width <= 1024);
      setIsDesktop(width > 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (categories?.length > 0 && !hasShownSkeleton) {
      setHasShownSkeleton(true);
      setTimeout(() => {
        setShowContent(true);
      }, 2000);
    }
  }, [categories, hasShownSkeleton]);

  const categoriasOrdenadas = Array.isArray(categories)
    ? [...categories].sort((a, b) => a.id - b.id)
    : [];

  const getCategoryImage = (category) => {
    if (category.img?.data?.[0]?.attributes?.url) {
      return category.img.data[0].attributes.url;
    }
    return categoryImages[category.nombre] || logo;
  };

  const CategorySkeleton = () => (
    <Box 
      style={{
        ...styles.card,
        width: isMobile ? "100%" : isTablet ? "48%" : "32%",
        backgroundColor: "#f0f0f0",
        overflow: "hidden"
      }}
    >
      <Skeleton 
        height="100%"
        startColor="gray.100"
        endColor="gray.300"
        borderRadius="24px"
      />
      <Box 
        position="absolute"
        bottom="8px"
        left="50%"
        transform="translateX(-50%)"
        width="90%"
      >
        <Skeleton
          height="44px"
          borderRadius="24px"
          startColor="gray.200"
          endColor="gray.400"
        />
      </Box>
    </Box>
  );

  return (
    <div style={styles.container}>
      <h2 style={styles.titSection} className="titles">Nuestras categorías</h2>
      <div style={{
        ...styles.container2,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: isDesktop ? "center" : isMobile ? "center" : "space-between",
        gap: "1rem",
        maxWidth: "100%",
      }}>
        {(!showContent && !hasShownSkeleton) ? (
          [...Array(4)].map((_, i) => <CategorySkeleton key={i} />)
        ) : (
          categoriasOrdenadas.map((categoria) => (
            <Box
              as={NavLink}
              to={`/${formatUrl(categoria.nombre)}`}
              key={categoria.id}
              style={{
                ...styles.card,
                backgroundImage: `url(${getCategoryImage(categoria)})`,
                width: isMobile ? "100%" : isTablet ? "48%" : "15%",
                margin: isDesktop ? "0.5rem" : "0",
              }}
            >
              <Button style={styles.Button} id="botonCategorias">
                <h3 style={styles.title} className="titles">{categoria.nombre}</h3>
              </Button>
            </Box>
          ))
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: "2rem",
    width: "100%",
  },
  container2: {
    display: "flex",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    justifyItems: "center",
  },
  card: {
    height: "200px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: "white",
    fontSize: "1.5rem",
    fontWeight: "bold",
    borderRadius: "24px",
    padding: ".5rem",
  },
  title: {
    textAlign: "flex-end",
  },
  Button: {
    background: "black",
    color: "white",
    borderRadius: "24px",
    width: "100%",
    height: "44px",
  },
  titSection: {
    display: "flex",
    width: "100%",
    fontSize: "2rem",
    paddingBottom: "1rem",
  },
};

export default Categorias;